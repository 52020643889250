import * as React from 'react'
import Seo from '../components/seo'
// import Video from '../components/video'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const Program = () => {
  return (
    <>
      <Seo title='Ostvareno i program za buduće razdoblje' />
      <Layout>
        {/* <StaticImage
          src='../images/zaokruzi-marinkozic.jpg'
          alt='Marinko Žic - načelnik prijatelj'
          placeholder='blurred'
          quality='100'
          as='div'
          className='my-8 wide'
        /> */}

        <section className='objave my-8'>
          <div className='grid grid-cols-objavefit gap-8 my-8'>
            <article>
              <a href='/MARINKO21-letak-Ostvareno.pdf'>
                <StaticImage
                  src='../images/letak-ostvareno.jpg'
                  alt='Marinko Žic'
                  layout='constrained'
                  placeholder='blur'
                  quality='100'
                  objectFit='contain'
                  objectPosition='0 0'
                  imgClassName='letak-slika'
                />
                <p>Letak: Ostvareno 2013.-2021.</p>
              </a>
            </article>
            <article>
              <a href='/MARINKO21-letak-lista.pdf'>
                <StaticImage
                  src='../images/letak-lista-program.jpg'
                  alt='Marinko Žic'
                  layout='constrained'
                  placeholder='blur'
                  quality='100'
                  objectFit='contain'
                  objectPosition='0 0'
                  imgClassName='letak-slika'
                />
                <p>Letak: Lista i kapitalni projekti 2021.-2025.</p>
              </a>
            </article>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Program
